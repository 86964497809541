import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import LoadingIndicator from '../LoadingIndicator'
import { fetchConsumerQuotesSummary } from '../../utilities/apiService'
import QuoteTable from '../tables/QuoteTable'

const QuoteSummary = () => {
  const navigate = useNavigate()
  const [quotes, setQuotes] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = useCallback(async () => {
    try {
      const fetchedQuotes = await fetchConsumerQuotesSummary()
      const orderedQuotes = _.orderBy(fetchedQuotes, 'quoteEffectiveDate', 'desc')
      setQuotes(orderedQuotes)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login?sessionExpired=true')
      }
      setIsLoading(false)
    }
  }, [navigate])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  if (isLoading) return <LoadingIndicator text="Loading Quotes..." />
  return <QuoteTable title="Quotes" quotes={quotes} />
}

export default QuoteSummary
