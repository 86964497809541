import React from 'react'
import { Navigate } from 'react-router-dom'
import UpdatePassword from './UpdatePassword'
import UserHome from './UserHome'

function Home({ auth }) {
  if (auth.user.lastPasswordChange === null) return <UpdatePassword auth={auth} />
  if (auth.user.role === 'admin') return <Navigate to="/admin" />
  if (auth.user.role === 'broker' || auth.user.role === 'brokerAdmin') return <Navigate to="/broker/policies" />
  if (auth.user.role === 'user') return <UserHome auth={auth} />
}

export default Home
