import React from 'react'
// import BrokerDownloadButton from '../broker/BrokerDownloadButton'
import dayjs from 'dayjs'
import { Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material'
import { Box, Typography } from '@mui/material'

const printStatus = (status) => {
  switch (status) {
    case 0:
      return 'In Force'
    case 1:
      return 'Converted'
    case 2:
      return 'Binder'
    case 3:
      return 'Application'
    case 4:
      return 'Prospect'
    case 5:
      return 'In Progress'
    case 6:
      return 'Pending Approval'
    case 7:
      return 'Cancelled'
    case 8:
      return 'Lapsed'
    case 10:
      return 'Quote Opened'
    case 11:
      return 'Quote Closed'
    case 12:
      return 'Out of Sequence'
    case 13:
      return 'Extended Reporting Only'
    case 14:
      return 'Cancelled Binder'
    case 15:
      return 'Quoted'
    case 16:
      return 'Suspended'
    case 98:
      return 'Expired'
    case 99:
      return 'Expired'
    default:
      return 'No Status Available'
  }
}

const tableStyle = {
  color: 'primary.main',
  border: `10px solid rgba(37, 83, 121, 0.2)`,
  borderCollapse: 'separate',
  borderRadius: '4px',
  boxShadow: `rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px`,
  '& .MuiTableRow-root': {
    '&:nth-of-type(2n)': {
      backgroundColor: '#f8f6ff',
    },
  },
}

const QuoteTable = ({ quotes, title }) => {
  return (
    <Box sx={{ mt: 4, mb: 7 }}>
      <Table sx={tableStyle}>
        <TableHead sx={{ backgroundColor: 'primary.main' }}>
          <TableRow>
            <TableCell colSpan="8">
              <Typography
                variant="h6"
                gutterBottom={false}
                noWrap
                component="h2"
                sx={{
                  fontWeight: 500,
                  color: 'white',
                }}>
                {title}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {quotes?.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" colSpan="8" style={{ textAlign: 'center' }}>
                <Typography paragraph>No quotes available at this time</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <strong>Number</strong>
              </TableCell>
              <TableCell>
                <strong>Version</strong>
              </TableCell>
              <TableCell>
                <strong>Effective</strong>
              </TableCell>
              <TableCell>
                <strong>Expiry</strong>
              </TableCell>
              <TableCell>
                <strong>Type</strong>
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
              <TableCell>
                <strong>Document Name</strong>
              </TableCell>
              <TableCell style={{ width: 180 }} />
            </TableRow>
            {quotes.map((quote) => (
              <TableRow key={`${quote.quoteKey}-${quote.quoteVersion}`}>
                <TableCell component="th" scope="row">
                  {quote.quoteKey}
                </TableCell>
                <TableCell>{quote.quoteVersion}</TableCell>
                <TableCell>{dayjs(quote.quoteEffectiveDate).format('MM/DD/YYYY')}</TableCell>
                <TableCell>{dayjs(quote.quoteExpiryDate).format('MM/DD/YYYY')}</TableCell>
                <TableCell>{quote.quoteRelatedTransactionType}</TableCell>
                <TableCell>{printStatus(quote.status)}</TableCell>
                <TableCell>No Document Available</TableCell>
                <TableCell align="right" style={{ width: 180 }}>
                  {/* <BrokerDownloadButton fileName={document.fileName} documentKey={document.documentKey} /> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </Box>
  )
}

export default QuoteTable
