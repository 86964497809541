import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

const TitleBanner = ({ pageTitle }) => {
  return (
    <Box
      component="header"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: 'primary.dark',
        color: 'white',
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h4" component="h1">
          {pageTitle}
        </Typography>
      </Container>
    </Box>
  )
}

export default TitleBanner
