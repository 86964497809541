import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography, Breadcrumbs, Link, Box } from '@mui/material'
import AdminLayout from '../layouts/AdminLayout'
import EditUserForm from '../components/forms/EditUserForm'
import { createUser } from '../utilities/apiService'

const CreateUser = ({ auth }) => {
  const [message, setMessage] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = async (data) => {
    try {
      setMessage(null)
      setIsLoading(true)
      const request = await createUser(data)
      setMessage({ status: request.status, message: request.message })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setMessage({ status: 'error', message: error.message })
    }
  }
  return (
    <AdminLayout auth={auth}>
      <Helmet>
        <title>Create User - Lawyers' Mutual Insurance Company Policy Hub</title>
      </Helmet>
      <Box component="div" sx={{ py: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Lawyers' Mutual Policy Hub
          </Link>
          <Link underline="hover" color="inherit" href="/admin">
            Admin
          </Link>
          <Typography color="primary">
            <strong>Create User</strong>
          </Typography>
        </Breadcrumbs>
      </Box>
      <Typography
        variant="h5"
        gutterBottom
        component="h2"
        sx={{
          fontWeight: 400,
        }}>
        Create User
      </Typography>
      <EditUserForm message={message} onSubmit={onSubmit} isLoading={isLoading} createMode />
    </AdminLayout>
  )
}

export default CreateUser
