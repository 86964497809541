import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import DefaultLayout from '../layouts/DefaultLayout'
import RegisterAccountForm from '../components/forms/RegisterAccountForm'

const RegisterAccount = () => {
  const [message, setMessage] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = async (data) => {
    try {
      setMessage(null)
      setIsLoading(true)
      const request = await axios.post('/auth/register', data)
      if (await request.data.success) {
        setIsLoading(false)
        setMessage({
          status: 'success',
          message: 'Account successfully registered, please check your email to log in.',
        })
      } else {
        setIsLoading(false)
        setMessage({
          status: 'error',
          message: 'Could not register your account, please check that all information is entered correctly.',
        })
      }
    } catch (error) {
      setIsLoading(false)
      setMessage({
        status: 'error',
        message: 'Could not register your account, please check that all information is entered correctly.',
      })
    }
  }
  return (
    <DefaultLayout pageTitle="Register Your Account">
      <Helmet>
        <title>Lawyers' Mutual Insurance Company Policy Hub - Register Your Account</title>
      </Helmet>
      <RegisterAccountForm message={message} onSubmit={onSubmit} isLoading={isLoading} />
    </DefaultLayout>
  )
}

export default RegisterAccount
