import React from 'react'
import { Helmet } from 'react-helmet-async'
import DefaultLayout from '../layouts/DefaultLayout'
import { Login } from '../components/Login'

const Signin = () => {
  return (
    <DefaultLayout pageTitle="Policy Hub">
      <Helmet>
        <title>Lawyers' Mutual Insurance Company Policy Hub - Log In</title>
      </Helmet>
      <Login location="main" />
    </DefaultLayout>
  )
}

export default Signin
