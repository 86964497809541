import React from 'react'
import { Helmet } from 'react-helmet-async'
import BrokerLayout from '../layouts/BrokerLayout'
import { Typography, Breadcrumbs, Link, Box } from '@mui/material'
import QuoteSummary from '../components/broker/QuoteSummary'

const BrokerQuotes = ({ auth }) => {
  return (
    <BrokerLayout auth={auth}>
      <Helmet>
        <title>Quotes - {auth.broker.brokerName} - Lawyers' Mutual Insurance Company Policy Hub</title>
      </Helmet>
      <Box component="div" sx={{ py: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/broker">
            Lawyers' Mutual Policy Hub
          </Link>
          <Link underline="hover" color="inherit" href="/broker">
            Brokers
          </Link>
          <Typography color="primary">
            <strong>Quotes Overview</strong>
          </Typography>
        </Breadcrumbs>
      </Box>
      <QuoteSummary auth={auth} />
    </BrokerLayout>
  )
}

export default BrokerQuotes
