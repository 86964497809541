import api from './authenticatedApiRequest'

// Fetch All Users
export const fetchUsers = async () => {
  try {
    const response = await api.get('/user/')
    return response.data
  } catch (error) {
    throw error
  }
}

// Fetch All Brokers
export const fetchBrokers = async () => {
  try {
    const response = await api.get('/broker/')
    return response.data
  } catch (error) {
    throw error
  }
}

// Fetch Broker Policies Summary
export const fetchBrokerPoliciesSummary = async () => {
  try {
    const response = await api.get('/broker/policies/summary')
    return response.data.policies
  } catch (error) {
    throw error
  }
}

// Fetch Broker Policy Details
export const fetchBrokerPolicyDetails = async (policyKey, policyVersion) => {
  try {
    const response = await api.get(`/broker/policies/${policyKey}/${policyVersion}/details`)
    return response.data
  } catch (error) {
    throw error
  }
}

// Fetch Broker Quotes Summary
export const fetchBrokerQuotesSummary = async () => {
  try {
    const response = await api.get('/broker/quotes/summary')
    return response.data.quotes
  } catch (error) {
    throw error
  }
}

// Fetch Broker Quote Details
export const fetchBrokerQuoteDetails = async (quoteKey, quoteVersion) => {
  try {
    const response = await api.get(`/broker/quote/${quoteKey}/${quoteVersion}`)
    return response.data
  } catch (error) {
    throw error
  }
}

// Fetch Consumer Policies Summary
export const fetchConsumerPoliciesSummary = async () => {
  try {
    const response = await api.get(`/consumer/policies/summary`)
    return response.data
  } catch (error) {
    throw error
  }
}

// Fetch Consumer Quotes Summary
export const fetchConsumerQuotesSummary = async () => {
  try {
    const response = await api.get(`/consumer/quotes/summary`)
    return response.data
  } catch (error) {
    throw error
  }
}

// Fetch Consumer Policy Details
export const fetchConsumerPolicyDetails = async (policyKey) => {
  try {
    const response = await api.get(`/consumer/policies/${policyKey}`)
    return response.data
  } catch (error) {
    throw error
  }
}

// Fetch Consumer Policy Letters
export const fetchConsumerPolicyLetters = async (policyKey, policyVersion) => {
  try {
    const response = await api.get(`/consumer/policy/${policyKey}/${policyVersion}/letters`)
    return response.data
  } catch (error) {
    throw error
  }
}
// Fetch Consumer Policy Documents
export const fetchConsumerPolicyDocuments = async (policyKey, policyVersion) => {
  try {
    const response = await api.get(`/consumer/policy/${policyKey}/${policyVersion}/documents`)
    return response.data
  } catch (error) {
    throw error
  }
}
// Fetch Consumer Policy Receivable Notices
export const fetchConsumerPolicyReceivableNotices = async (policyKey, policyVersion) => {
  try {
    const response = await api.get(`/consumer/policy/${policyKey}/${policyVersion}/receivable-notices`)
    return response.data
  } catch (error) {
    throw error
  }
}

// Fetch Consumer Letters
export const fetchConsumerLetters = async () => {
  try {
    const response = await api.get(`/consumer/letters`)
    return response.data
  } catch (error) {
    throw error
  }
}
// Fetch Consumer Receivable Notices
export const fetchConsumerReceivableNotices = async () => {
  try {
    const response = await api.get(`/consumer/receivable-notices`)
    return response.data
  } catch (error) {
    throw error
  }
}

// Create a User
export const createUser = async (user) => {
  try {
    const response = await api.post('/user/create', user)
    return response.data
  } catch (error) {
    throw error
  }
}

// Update a User

export const updateUser = async (user) => {
  try {
    const response = await api.post('/user/update', user)
    return response.data
  } catch (error) {
    throw error
  }
}

// Delete a User
export const deleteUser = async (id) => {
  try {
    const response = await api.post('/user/delete', { id: id })
    return response.data
  } catch (error) {
    throw error
  }
}

// Update User Password
export const updatePassword = async (data) => {
  try {
    const response = await api.post('/user/update-password', data)
    return response.data
  } catch (error) {
    throw error
  }
}

// Download Broker Doc
export const downloadBrokerDocument = async (documentKey) => {
  try {
    const response = await api.get(`/broker/download/${documentKey}`)
    return response.data
  } catch (error) {
    throw error
  }
}

// Download Consumer Doc
export const downloadDocument = async (documentKey) => {
  try {
    const response = await api.get(`/consumer/download/${documentKey}`)
    return response.data
  } catch (error) {
    throw error
  }
}
