import React, { useEffect, useCallback, useState, useMemo, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled, alpha } from '@mui/material/styles'
import _, { debounce } from 'lodash'
import dayjs from 'dayjs'
import LoadingIndicator from '../LoadingIndicator'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Typography, AppBar, Toolbar, InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { fetchBrokerQuotesSummary } from '../../utilities/apiService'

const tableStyle = {
  mt: 2,
  color: 'primary.main',
  border: `10px solid rgba(37, 83, 121, 0.2)`,
  boxShadow: `rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px`,
  '& .MuiDataGrid-row': {
    '&:nth-of-type(2n)': {
      backgroundColor: '#f8f6ff',
    },
    '&:hover': {
      backgroundColor: 'rgba(37, 83, 121, 0.2)',
      cursor: 'pointer',
    },
  },
  '& .MuiDataGrid-cell': {
    cursor: 'pointer',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}
const formatDate = (date) => {
  return dayjs(date).format('MM/DD/YYYY')
}

const BrokerQuoteSummary = ({ auth }) => {
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(10)
  const [quotes, setQuotes] = useState([])
  const [query, setQuery] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { brokerName } = auth.broker

  const fetchData = useCallback(async () => {
    try {
      const brokerQuotes = await fetchBrokerQuotesSummary()
      setQuotes(brokerQuotes)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/broker/login?sessionExpired=true')
      }
      setIsLoading(false)
    }
  }, [navigate])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleViewDetails = (quote) => {
    navigate('/broker/quotes/quote-details', { state: quote })
  }

  const searchHandler = (event) => {
    const { value } = event.target
    setQuery(value)
  }
  const debouncedSearchHandler = useMemo(() => debounce(searchHandler, 600), [])

  const filteredQuotes = () => {
    if (!query) return quotes
    const result = _.filter(quotes, function (quote) {
      return quote.clientName.toLowerCase().indexOf(query.toLowerCase()) > -1
    })

    return result
  }

  const columns = [
    {
      field: 'clientName',
      headerName: 'Client Name',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box component="span" sx={{ fontWeight: 600 }}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'quoteKey',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      headerName: 'Quote Number',
      flex: 1,
    },
    {
      field: 'policyEffectiveDate',
      headerName: 'Policy Effective',
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        return formatDate(params.value)
      },
    },
    {
      field: 'policyExpiryDate',
      headerName: 'Policy Expiration',
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        return formatDate(params.value)
      },
    },
  ]

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }))

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }))

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '20ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }))

  const TableToolBar = () => {
    return (
      <AppBar position="relative">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            gutterBottom={false}
            noWrap
            component="div"
            sx={{
              fontWeight: 500,
            }}>
            Quotes for {brokerName}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Filter by Name"
                defaultValue={query}
                onChange={debouncedSearchHandler}
                autoFocus
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box>
        </Toolbar>
      </AppBar>
    )
  }

  if (isLoading) {
    return (
      <Fragment>
        <LoadingIndicator text="Loading Quotes..." />
      </Fragment>
    )
  }

  return (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: 'policyExpiryDate', sort: 'asc' }],
        },
      }}
      autoHeight
      disableSelectionOnClick={true}
      components={{ Toolbar: TableToolBar }}
      onRowClick={(params) => handleViewDetails(params.row)}
      rows={filteredQuotes(query, quotes)}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 20, 50, 100]}
      pagination
      columns={columns}
      pageSize={pageSize}
      loading={isLoading}
      sx={tableStyle}
    />
  )
}

export default BrokerQuoteSummary
