import React from 'react'
import { Navigate } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Container, Box } from '@mui/material'

const AdminLayout = ({ children, pageTitle, auth }) => {
  if (auth.user.role !== 'admin') return <Navigate to="/" />
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header pageTitle={pageTitle} auth={auth} navBar={true} />
      <Container maxWidth="lg" component="main" sx={{ padding: '20px 0px 40px' }}>
        {children}
      </Container>
      <Footer />
    </Box>
  )
}

export default AdminLayout
