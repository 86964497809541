import React from 'react'
import NavLink from '../NavLink'
import { styled } from '@mui/material/styles'

const NavLinks = `
display: inline-block;
background: transparent;
border: 0;
border-radius:0;
color: #9B9B9B;
font-weight: bold;
font-size: 14px;
text-decoration: none;
line-height: 1.5;
display: inline-block;
text-transform: uppercase;
padding: 10px 25px 15px;
vertical-align: bottom;
&:hover {
  background: rgba(255,255,255, .1)
};
&.active{
  background: white;
  color: #1A1449;
};
`

const NavBar = styled('nav')`
  border-bottom: 1px solid #979797;
`

const NavBarLink = styled(NavLink)`
  ${NavLinks}
`

const UserNav = () => {
  return (
    <NavBar>
      <NavBarLink activeClassName="active" to="/policies">
        Policies
      </NavBarLink>
      <NavBarLink activeClassName="active" to="/policies/quotes">
        Quotes
      </NavBarLink>
      <NavBarLink activeClassName="active" to="/policies/documents-letters">
        Documents/Letters
      </NavBarLink>
      {/* <NavBarLink activeClassName="active" to="/policies/report-claim">
        Report A Claim
      </NavBarLink> */}
    </NavBar>
  )
}

export default UserNav
