import React, { Fragment, useState } from 'react'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { LoadingButton } from '@mui/lab'
import { Container, TextField, Typography, CircularProgress } from '@mui/material'

const ForgotPasswordForm = () => {
  const [message, setMessage] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email').required('Email is required'),
  })
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
    },
  }
  const { handleSubmit, formState, control } = useForm(formOptions)
  const { errors } = formState
  const onSubmit = async (data) => {
    setMessage(null)
    setIsLoading(true)
    axios
      .post('/api/user/forgot-password', data)
      .then((response) => {
        if (response.data.success) {
          setSuccess(true)
          setMessage({
            status: 'success',
            message: 'Please check your email for instructions on resetting your password',
          })
          setIsLoading(false)
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        setSuccess(false)
        setMessage({
          status: 'error',
          message: 'Could not process your request at this time. Please try again later.',
        })
        setIsLoading(false)
      })
  }
  return (
    <Fragment>
      <Typography
        variant="h5"
        gutterBottom
        component="h2"
        sx={{
          fontWeight: 400,
        }}>
        {success ? 'Password Reset Sent' : 'Request Password Reset'}
      </Typography>
      {message && (
        <Typography paragraph sx={{ color: `${message.status}.main` }}>
          {message.message}
        </Typography>
      )}
      <Typography paragraph>Please enter the email address you use for your account.</Typography>
      <Container
        component="form"
        autocomplete="off"
        disableGutters
        maxWidth="xs"
        sx={{
          mx: 0,
        }}
        onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              error={errors.email}
              label="Email"
              helperText={errors.email?.message}
              variant="standard"
            />
          )}
        />

        <LoadingButton
          type="submit"
          loading={isLoading}
          variant="contained"
          disabled={isLoading}
          disableElevation
          color="secondary"
          size="large"
          loadingIndicator={<CircularProgress color="inherit" size={16} thickness={5} />}
          sx={{
            minWidth: '150px',
            mt: 4,
          }}>
          Request Reset
        </LoadingButton>
      </Container>
    </Fragment>
  )
}

export default ForgotPasswordForm
