import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import LoadingIndicator from '../LoadingIndicator'
import { Table, TableBody, TableCell, TableRow, TableHead, Box, Typography, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DocumentTable from '../tables/DocumentTable'
import { fetchBrokerPolicyDetails } from '../../utilities/apiService'

const tableStyle = {
  color: 'primary.main',
  border: `10px solid rgba(37, 83, 121, 0.2)`,
  borderCollapse: 'separate',
  borderRadius: '4px',
  boxShadow: `rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px`,
  '& .MuiTableRow-root': {
    '&:nth-of-type(2n)': {
      backgroundColor: '#f8f6ff',
    },
  },
}

const formatCurrency = (value) => {
  const absValue = Math.abs(value)
  const returnString = Number(absValue).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  return value < 0 ? '<strong>(' + returnString + ')</strong>' : returnString
}

const PolicyDetail = ({ policy }) => {
  const navigate = useNavigate()
  const { policyKey, policyVersion } = policy
  const [policyDetails, setPolicyDetails] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = useCallback(async () => {
    try {
      const policyDetails = await fetchBrokerPolicyDetails(policyKey, policyVersion)
      setPolicyDetails(policyDetails)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/broker/login?sessionExpired=true')
      }
      setIsLoading(false)
    }
  }, [navigate, policyKey, policyVersion])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <>
      {isLoading ? (
        <Fragment>
          <LoadingIndicator text="Loading Policy Details..." />
        </Fragment>
      ) : (
        <Fragment>
          <Box sx={{ mt: 2, mb: 7 }}>
            <Table sx={tableStyle}>
              <TableHead sx={{ backgroundColor: 'primary.main' }}>
                <TableRow>
                  <TableCell colSpan="2">
                    <Typography
                      variant="h6"
                      gutterBottom={false}
                      noWrap
                      component="h2"
                      sx={{
                        fontWeight: 500,
                        color: 'white',
                      }}>
                      {policy.policyKey} - {policy.primaryInsuredName}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Client Name</strong>
                  </TableCell>
                  <TableCell align="right">{policy.clientName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Policy Number</strong>
                  </TableCell>
                  <TableCell align="right">{policy.policyKey}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Policy Effective Date</strong>
                  </TableCell>
                  <TableCell align="right">{dayjs(policy.policyEffectiveDate).format('MM/DD/YYYY')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Policy Expiry Date</strong>
                  </TableCell>
                  <TableCell align="right">{dayjs(policy.policyExpiryDate).format('MM/DD/YYYY')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Written Premium</strong>
                  </TableCell>
                  <TableCell
                    align="right"
                    dangerouslySetInnerHTML={{ __html: formatCurrency(policy.writtenPremium) }}
                  />
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Policy Balance</strong>{' '}
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="If your balance shows as zero and your policy was financed, you will need to check your outstanding balance with your finance provider.">
                      <InfoOutlinedIcon color="primary" fontSize="small" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right" dangerouslySetInnerHTML={{ __html: formatCurrency(policy.balance) }} />
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Broker Name</strong>
                  </TableCell>
                  <TableCell align="right">{policy.brokerName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Claims</strong>
                  </TableCell>
                  <TableCell align="right">{policy.claims}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          {policyDetails.transactionDetails?.insureds && (
            <Box sx={{ mt: 4, mb: 7 }}>
              <Table sx={tableStyle}>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                        gutterBottom={false}
                        noWrap
                        component="h2"
                        sx={{
                          fontWeight: 500,
                          color: 'white',
                        }}>
                        Insureds
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <strong>Name of Insured</strong>
                    </TableCell>
                  </TableRow>
                  {policyDetails.transactionDetails.insureds.map((insured) => (
                    <TableRow key={insured.contact.contactKey}>
                      <TableCell component="th" scope="row">
                        {insured.contact.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
          <DocumentTable title="Current Insurance Documents" documents={policyDetails.documents} type="documents" />
          <DocumentTable title="Receivable Notices" documents={policyDetails.notices} type="notices" />
          <DocumentTable title="Letters" documents={policyDetails.letters} type="letters" />
        </Fragment>
      )}
    </>
  )
}

export default PolicyDetail
