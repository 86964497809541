import React from 'react'
import { Helmet } from 'react-helmet-async'
import UserLayout from '../layouts/UserLayout'
import UserNav from '../components/users/UserNav'
import PolicyDetail from '../components/users/PolicyDetail'

const UserPolicyDetail = ({ auth }) => {
  return (
    <UserLayout auth={auth}>
      <Helmet>
        <title>Policy Detail - Lawyers' Mutual Insurance Company Policy Hub</title>
      </Helmet>
      <UserNav />
      <PolicyDetail />
    </UserLayout>
  )
}

export default UserPolicyDetail
