import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Logo from '../images/lawyers-mutual-logo.svg'
import { Box } from '@mui/material'
import Navbar from '../components/Navbar'

const Header = ({ pageTitle, auth, navBar }) => {
  const HeaderWrapper = styled(Box)`
    height: ${navBar ? '116px' : '212px'};
    max-width: ${navBar ? '1200px' : '1350px'};
    margin: 0 auto;
    background: white;
    position: relative;
    display: flex;
    align-items: center;
  `

  const LogoLink = styled(Link)`
    bottom: 10px;
    display: block;
    width: ${navBar ? '175px' : '260px'};
    img {
      width: 100%;
    }
  `

  return (
    <Fragment>
      <Box component="header">
        <HeaderWrapper sx={{ p: 2 }}>
          <LogoLink to="/">
            <img src={Logo} alt="Lawyers' Mutual Insurance Co." />
          </LogoLink>
        </HeaderWrapper>
      </Box>
      {auth && <Navbar auth={auth} />}
    </Fragment>
  )
}

export default Header
