import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import axios from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import {
  InputLabel,
  Box,
  Container,
  Input,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Link,
  Typography,
  Stack,
  CircularProgress,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export const Login = ({ location }) => {
  const [message, setMessage] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
    password: Yup.string().max(255).required('Password is required'),
  })
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  }
  const { handleSubmit, formState, control } = useForm(formOptions)
  const { errors } = formState

  const onSubmit = async (data) => {
    try {
      setMessage(null)
      setIsLoading(true)
      const request = await axios.post('/auth/login', { ...data, location })
      localStorage.setItem('token', request.data.token)
      window.location.href = request.data.url
    } catch (error) {
      setIsLoading(false)
      setMessage({ status: 'error', message: 'Error logging in. Check that email and password are correct.' })
    }
  }
  return (
    <Container
      disableGutters
      maxWidth="xs"
      sx={{
        margin: 0,
      }}>
      <Typography
        variant="h5"
        gutterBottom
        component="h2"
        sx={{
          fontWeight: 400,
        }}>
        Login
      </Typography>
      {message && (
        <Typography paragraph sx={{ color: `${message.status}.main` }}>
          {message.message}
        </Typography>
      )}
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.email}
                label="Email"
                helperText={errors.email?.message}
                variant="standard"
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormControl variant="standard">
                <InputLabel error={errors.password} htmlFor="password">
                  Password
                </InputLabel>
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  error={errors.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error={errors.password} id="password-help-text">
                  {errors.password?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Stack>
        <Box
          sx={{
            py: 6,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            disabled={isLoading}
            disableElevation
            color="secondary"
            size="large"
            loadingIndicator={<CircularProgress color="inherit" size={16} thickness={5} />}
            sx={{
              minWidth: '150px',
            }}>
            Log In
          </LoadingButton>
          <Link underline="hover" component={RouterLink} to="/forgot-password">
            Forgot Password?
          </Link>
        </Box>
      </form>
    </Container>
  )
}
