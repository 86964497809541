import React from 'react'
import { useNavigate } from 'react-router-dom'
import DefaultLayout from '../layouts/DefaultLayout'
import { Typography, Container, Button } from '@mui/material'

const ResetPassword = () => {
  const navigate = useNavigate()
  const handleButtonClick = () => {
    navigate('/')
  }
  return (
    <DefaultLayout pageTitle="Page Not Found">
      <Container
        maxWidth="sm"
        sx={{
          textAlign: 'center',
          py: 5,
        }}>
        <Typography
          variant="h1"
          component="h1"
          gutterBottom={false}
          sx={{
            fontSize: '10rem',
            fontWeight: 700,
            color: 'primary.dark',
          }}>
          404
        </Typography>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            fontSize: '3rem',
            fontWeight: 700,
            color: 'primary.dark',
          }}>
          Oh No! Page Not Found!
        </Typography>
        <Typography gutterBottom paragraph>
          Either something went wrong or the page doesn't exist anymore.
        </Typography>
        <Button variant="contained" size="large" color="primary" onClick={handleButtonClick} sx={{ mt: 3 }}>
          Take Me Home
        </Button>
      </Container>
    </DefaultLayout>
  )
}

export default ResetPassword
