import React, { Fragment, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  InputLabel,
  Container,
  Input,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  CircularProgress,
} from '@mui/material'
import { passwordRegex } from '../../utilities/constants'

const UpdatePasswordForm = ({ onSubmit, isLoading, buttonValue = 'Set Password' }) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(12, 'Password must be at least 12 characters')
      .matches(
        passwordRegex,
        'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  }
  const { handleSubmit, formState, control } = useForm(formOptions)
  const { errors } = formState
  return (
    <Fragment>
      <Container
        component="form"
        disableGutters
        maxWidth="xs"
        sx={{
          mx: 0,
        }}
        onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormControl variant="standard">
                <InputLabel error={!!errors.password} htmlFor="password">
                  Password
                </InputLabel>
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  error={!!errors.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error={!!errors.password} id="password-help-text">
                  {errors.password?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <FormControl variant="standard">
                <InputLabel error={!!errors.confirmPassword} htmlFor="confirmPassword">
                  Confirm Password
                </InputLabel>
                <Input
                  id="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  error={!!errors.confirmPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error={!!errors.confirmPassword} id="password-help-text">
                  {errors.confirmPassword?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Stack>
        <LoadingButton
          type="submit"
          loading={isLoading}
          variant="contained"
          disabled={isLoading}
          disableElevation
          color="secondary"
          size="large"
          loadingIndicator={<CircularProgress color="inherit" size={16} thickness={5} />}
          sx={{
            minWidth: '150px',
            mt: 4,
          }}>
          {buttonValue}
        </LoadingButton>
      </Container>
    </Fragment>
  )
}

export default UpdatePasswordForm
