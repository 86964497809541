import axios from 'axios'

// Create an axios instance
const api = axios.create({
  baseURL: '/api', // your api base URL
  headers: {
    'Content-Type': 'application/json',
  },
})

// Request interceptor for API calls
api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    // Do something with response error
    if (error.response.status === 403 && !originalRequest._retry) {
      // originalRequest._retry = true
      // const access_token = await refreshAccessToken() // Assuming you have a function to get a new token
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
      return api(originalRequest)
    }
    return Promise.reject(error)
  }
)

export default api
