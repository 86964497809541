import React from 'react'
import { Helmet } from 'react-helmet-async'
import { styled } from '@mui/material'
import { Link } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'
import UserLayout from '../layouts/UserLayout'
import DescriptionIcon from '@mui/icons-material/Description'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

const LinkButton = styled(Button)`
  border-radius: 0;
  min-width: 175px;
  margin-right: 20px;
  padding: 20px;
`

const UserHome = ({ auth }) => {
  return (
    <UserLayout auth={auth}>
      <Helmet>
        <title>Home - Lawyers' Mutual Insurance Company Policy Hub</title>
      </Helmet>
      <Box component="div" sx={{ py: 6 }}>
        <Typography
          variant="h6"
          gutterBottom
          component="h2"
          color="text.primary"
          sx={{
            fontWeight: 400,
          }}>
          {auth.user.firstName && <span>Hi {auth.user.firstName}! </span>}
          Welcome to the Lawyers' Mutual Insurance Company Consumer Portal,
          <br /> your access point to manage your insurance needs with us.
        </Typography>
      </Box>
      <Box>
        <LinkButton
          component={Link}
          to="/policies"
          size="large"
          variant="contained"
          disableElevation
          startIcon={<DescriptionIcon />}>
          Policy Hub
        </LinkButton>
        <LinkButton
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.lawyersmutual.com/paynow"
          variant="contained"
          size="large"
          disableElevation
          startIcon={<AttachMoneyIcon />}>
          Make A Payment
        </LinkButton>
      </Box>
    </UserLayout>
  )
}

export default UserHome
