import React, { Fragment, useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import LoadingIndicator from '../components/LoadingIndicator'

const RequireAuth = ({ children, redirectTo }) => {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [auth, setAuth] = useState(null)

  useEffect(() => {
    axios
      .get('/auth/current-session', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setAuth(data)
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate(`${redirectTo}?sessionExpired=true`)
        } else {
          navigate(redirectTo)
        }
      })
  }, [token, navigate, redirectTo])
  if (auth === null) return <LoadingIndicator text="Loading..." />
  return auth ? <Fragment>{React.cloneElement(children, { auth })}</Fragment> : <Navigate to={redirectTo} />
}

export default RequireAuth
