import React, { Fragment } from 'react'

import { Container, Stack, Typography, Box } from '@mui/material'

const ProfileInfo = ({ onSubmit, user }) => {
  return (
    <Fragment>
      <Container
        component="div"
        maxWidth="xs"
        disableGutters
        sx={{
          mx: 0,
          mt: 2,
          mb: 6,
        }}>
        <Stack spacing={2} direction="row">
          <Box>
            <Typography variant="caption" display="block">
              First Name
            </Typography>
            <Typography>{user.firstName}</Typography>
          </Box>
          <Box>
            <Typography variant="caption" display="block">
              Last Name
            </Typography>
            <Typography>{user.lastName}</Typography>
          </Box>
        </Stack>
        <Stack spacing={2} mt={2}>
          <Box>
            <Typography variant="caption" display="block">
              Email Address
            </Typography>
            <Typography>{user.email}</Typography>
          </Box>
        </Stack>
      </Container>
    </Fragment>
  )
}

export default ProfileInfo
