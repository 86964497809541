import React from 'react'
import { Navigate } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Container, Box } from '@mui/material'

const UserLayout = ({ children, pageTitle, auth }) => {
  if (auth.user.role !== 'user') return <Navigate to="/" />
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box>
        <Header pageTitle={pageTitle} auth={auth} navBar={true} />
        <Container maxWidth="lg" component="main" sx={{ padding: '20px 0 40px' }}>
          {children}
        </Container>
      </Box>
      <Footer />
    </Box>
  )
}

export default UserLayout
