import React from 'react'
import { useLocation } from 'react-router-dom'
import BrokerLayout from '../layouts/BrokerLayout'
import QuoteDetail from '../components/broker/QuoteDetail'
import { Typography, Breadcrumbs, Link, Box } from '@mui/material'

const BrokerQuoteDetail = ({ auth }) => {
  const location = useLocation()
  const quote = location.state
  return (
    <BrokerLayout auth={auth} pageTitle="Quote Details">
      <Box component="div" sx={{ py: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/broker">
            Lawyers' Mutual Policy Hub
          </Link>
          <Link underline="hover" color="inherit" href="/broker">
            Brokers
          </Link>
          <Link underline="hover" color="inherit" href="/broker/quotes">
            Quotes
          </Link>
          <Typography color="primary">
            <strong>Quote Details</strong>
          </Typography>
        </Breadcrumbs>
      </Box>
      <QuoteDetail quote={quote} />
    </BrokerLayout>
  )
}

export default BrokerQuoteDetail
