import React from 'react'
import { Helmet } from 'react-helmet-async'
import UserLayout from '../layouts/UserLayout'
import UserNav from '../components/users/UserNav'
import { Box } from '@mui/material'
import PolicyDocuments from '../components/users/PolicyDocuments'

const UserPolicyDocuments = ({ auth }) => {
  return (
    <UserLayout auth={auth}>
      <Helmet>
        <title>Policy Documents - Lawyers' Mutual Insurance Company Policy Hub</title>
      </Helmet>
      <UserNav />
      <Box sx={{ padding: '10px 25px' }}>
        <PolicyDocuments />
      </Box>
    </UserLayout>
  )
}

export default UserPolicyDocuments
