import React, { useState, useEffect, Fragment } from 'react'
import { useSearchParams, useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import { Typography } from '@mui/material'
import LoadingIndicator from '../components/LoadingIndicator'
import DefaultLayout from '../layouts/DefaultLayout'
import UpdatePasswordForm from '../components/forms/UpdatePasswordForm'

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const resetId = searchParams.get('resetId')
  const [isValid, setIsValid] = useState(null)
  const [passwordWasReset, setPasswordWasReset] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      if (!resetId) {
        navigate('/')
      }
      const request = await axios.post('/auth/validate-reset', { resetId })
      const { validReset } = await request.data
      if (validReset) {
        setIsValid(true)
      } else {
        setIsValid(false)
      }
    }

    fetchData().catch(console.error)
  }, [resetId, navigate])

  const onSubmit = async ({ password }) => {
    axios
      .post('/api/user/reset-password', { resetId, password })
      .then((response) => {
        if (response.data.success) {
          setPasswordWasReset(true)
          return axios.post('/auth/login', { email: response.data.user, password, location: 'passwordReset' })
        } else {
          throw new Error('Could not reset user password')
        }
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token)
        window.location.href = response.data.url
      })
      .catch((error) => {
        throw new Error('Could not reset user password')
      })
  }

  if (isValid === null) return <LoadingIndicator text="Loading..." />
  if (passwordWasReset) return <LoadingIndicator text="Logging In..." />
  return (
    <DefaultLayout pageTitle="Reset Password">
      <Typography
        variant="h5"
        gutterBottom
        component="h2"
        sx={{
          fontWeight: 400,
        }}>
        Password Reset
      </Typography>
      {isValid ? (
        <Fragment>
          <Typography paragraph>Please enter a new password for your account.</Typography>
          <UpdatePasswordForm onSubmit={onSubmit} />
        </Fragment>
      ) : (
        <Typography paragraph>
          The window to reset your password has expired, <Link to="/forgot-password">please try again.</Link>
        </Typography>
      )}
    </DefaultLayout>
  )
}

export default ResetPassword
