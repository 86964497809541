import React from 'react'
import { Helmet } from 'react-helmet-async'
import DefaultLayout from '../layouts/DefaultLayout'
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm'

const ForgotPassword = () => {
  return (
    <DefaultLayout pageTitle="Forgot Password">
      <Helmet>
        <title>Lawyers' Mutual Insurance Company Policy Hub - Forgot Password</title>
      </Helmet>
      <ForgotPasswordForm />
    </DefaultLayout>
  )
}

export default ForgotPassword
