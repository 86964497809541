import React from 'react'
import { Helmet } from 'react-helmet-async'
import UserLayout from '../layouts/UserLayout'
import UserNav from '../components/users/UserNav'
import QuoteSummary from '../components/users/QuoteSummary'
import { Box } from '@mui/material'

const UserQuotes = ({ auth }) => {
  return (
    <UserLayout auth={auth}>
      <Helmet>
        <title>Quotes - Lawyers' Mutual Insurance Company Policy Hub</title>
      </Helmet>
      <UserNav />
      <Box sx={{ padding: '10px 25px' }}>
        <QuoteSummary />
      </Box>
    </UserLayout>
  )
}

export default UserQuotes
