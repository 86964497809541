import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © Lawyers' Mutual Insurance Company"} {new Date().getFullYear()}
      {'. All rights reserved.'}
    </Typography>
  )
}

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Copyright />
      </Container>
    </Box>
  )
}
