import React from 'react'
import { Container, Typography, CircularProgress } from '@mui/material'

function LoadingIndicator({ text }) {
  return (
    <Container
      disableGutters
      sx={{
        padding: '80px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <CircularProgress size={150} thickness={3} color="primary" />
      {text && (
        <Typography variant="h6" gutterBottom paragraph sx={{ color: 'primary.main', m: 3, fontWeight: 400 }}>
          {text}
        </Typography>
      )}
    </Container>
  )
}

export default LoadingIndicator
