import React, { Fragment, useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { sortBy } from 'lodash'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { fetchBrokers } from '../../utilities/apiService'
import { passwordRegex } from '../../utilities/constants'

import { LoadingButton } from '@mui/lab'
import {
  InputLabel,
  Container,
  Select,
  Input,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  IconButton,
  // Button,
  InputAdornment,
  TextField,
  MenuItem,
  Typography,
  Stack,
  CircularProgress,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const userTypes = [
  { name: 'Admin', value: 'admin' },
  { name: 'Broker', value: 'broker' },
  { name: 'Direct Insured', value: 'user' },
]

const EditUserForm = ({ onSubmit, message, isLoading = false, createMode = false, user }) => {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [brokers, setBrokers] = useState([])

  const fetchData = useCallback(async () => {
    try {
      const brokerList = await fetchBrokers()
      const sortedBrokers = sortBy(brokerList, 'brokerName')
      setBrokers(sortedBrokers)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/broker/login?sessionExpired=true')
      }
    }
  }, [navigate])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  Yup.addMethod(Yup.string, 'noInjection', function (errorMessage) {
    // eslint-disable-next-line no-useless-escape
    const regex = /[";=<>{}()\[\]\/&]/
    return this.test('no-injection', errorMessage, function (value) {
      return value == null || !regex.test(value)
    })
  })

  const validationSchema = Yup.object().shape({
    newUser: Yup.boolean(),
    firstName: Yup.string().noInjection('First Name contains disallowed characters').required('First Name is required'),
    lastName: Yup.string().noInjection('Last Name contains disallowed characters').required('Last Name is required'),
    role: Yup.string().required('Please select a User Role'),
    email: Yup.string()
      .noInjection('Email contains disallowed characters')
      .email('Must be a valid email')
      .required('Email is required'),
    broker: Yup.string().when('role', {
      is: 'broker',
      then: Yup.string().required('Please select a broker'),
    }),
    clientKey: Yup.string().when('role', {
      is: 'user',
      then: Yup.string().noInjection('Client Key contains disallowed characters').required('Client Key is required'),
    }),
    zipCode: Yup.string().when('role', {
      is: 'user',
      then: Yup.string().noInjection('Zip code contains disallowed characters').required('Zipcode is required'),
    }),
    password: createMode
      ? Yup.string()
          .required('Password is required')
          .min(12, 'Password must be at least 12 characters')
          .matches(
            passwordRegex,
            'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
          )
      : Yup.string(),
    confirmPassword: createMode
      ? Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password')], 'Passwords must match')
      : Yup.string(),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      newUser: createMode,
      role: user?.role || 'user',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      broker: user?.key || '',
      clientKey: user?.key || '',
      zipCode: user?.zipCode || '',
      password: '',
      confirmPassword: '',
      sendInvite: createMode,
    },
  }
  const { handleSubmit, formState, watch, control } = useForm(formOptions)
  const { errors } = formState
  const userRole = watch('role')

  const submitForm = (data) => {
    onSubmit(data)
  }

  return (
    <Fragment>
      {message && (
        <Typography paragraph sx={{ color: `${message.status}.main` }}>
          {message.message}
        </Typography>
      )}
      <Container
        component="form"
        autoComplete="off"
        maxWidth="md"
        disableGutters
        sx={{
          mx: 0,
        }}
        onSubmit={handleSubmit(submitForm)}>
        <Stack spacing={2} direction="row">
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.firstName}
                label="First Name"
                helperText={errors.firstName?.message}
                variant="standard"
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.lastName}
                label="Last Name"
                helperText={errors.lastName?.message}
                variant="standard"
              />
            )}
          />
        </Stack>
        <Stack mt={2} spacing={2} direction="row">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.email}
                label="Email"
                helperText={errors.email?.message}
                variant="standard"
              />
            )}
          />
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <FormControl variant="standard" fullWidth error={!!errors.role}>
                <InputLabel htmlFor="role">User Role</InputLabel>
                <Select {...field} MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}>
                  {userTypes.map((userType) => (
                    <MenuItem key={userType.value} value={userType.value}>
                      {userType.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors.role} id="role-help-text">
                  {errors.role?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {userRole === 'broker' && (
            <Controller
              name="broker"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth error={!!errors.broker}>
                  <InputLabel htmlFor="broker">Broker</InputLabel>
                  <Select {...field} MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}>
                    <MenuItem value="">None</MenuItem>
                    {brokers.map((broker) => (
                      <MenuItem key={broker.brokerCode} value={broker.brokerCode}>
                        {broker.brokerName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.broker} id="broker-help-text">
                    {errors.broker?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}
        </Stack>
        {userRole === 'user' && (
          <Stack mt={2} spacing={2} direction="row">
            <Controller
              name="clientKey"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!errors.clientKey}
                  label="Client Key"
                  helperText={errors.clientKey?.message}
                  variant="standard"
                />
              )}
            />
            <Controller
              name="zipCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!errors.zipCode}
                  label="Zip Code"
                  helperText={errors.zipCode?.message}
                  variant="standard"
                />
              )}
            />
          </Stack>
        )}
        {createMode && (
          <Stack mt={2} spacing={2} direction="row">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel error={!!errors.password} htmlFor="password">
                    Temporary Password
                  </InputLabel>
                  <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    {...field}
                    error={!!errors.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error={!!errors.password} id="password-help-text">
                    {errors.password?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel error={!!errors.confirmPassword} htmlFor="confirmPassword">
                    Confirm Temporary Password
                  </InputLabel>
                  <Input
                    id="confirmPassword"
                    type={showPassword ? 'text' : 'password'}
                    {...field}
                    error={!!errors.confirmPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error={!!errors.confirmPassword} id="password-help-text">
                    {errors.confirmPassword?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Stack>
        )}
        {createMode && (
          <Stack mt={2} spacing={2} direction="row">
            <Controller
              name="sendInvite"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      color="primary"
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label="Send User Invite Email"
                />
              )}
            />
          </Stack>
        )}
        <Stack mt={4} spacing={2} direction="row">
          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            disabled={isLoading}
            disableElevation
            color="secondary"
            size="large"
            loadingIndicator={<CircularProgress color="inherit" size={16} thickness={5} />}
            sx={{
              minWidth: '150px',
            }}>
            {createMode ? 'Create User' : 'Update User'}
          </LoadingButton>
          {/* <Button
            variant="text"
            color="secondary"
            size="large"
            sx={{
              minWidth: '150px',
            }}>
            Cancel
          </Button> */}
        </Stack>
      </Container>
    </Fragment>
  )
}

export default EditUserForm
