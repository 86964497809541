import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Container, Box } from '@mui/material'

const SecureLayout = ({ children, pageTitle, auth }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header pageTitle={pageTitle} auth={auth} navBar={true} />
      <Container maxWidth="lg" component="main" sx={{ padding: '20px 0 40px' }}>
        {children}
      </Container>
      <Footer />
    </Box>
  )
}

export default SecureLayout
