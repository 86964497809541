import React, { useCallback, useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import LoadingIndicator from '../LoadingIndicator'
import PolicySummary from './PolicySummary'
import { fetchConsumerPoliciesSummary } from '../../utilities/apiService'

const PolicySummaryWrapper = () => {
  const navigate = useNavigate()
  const [policies, setPolicies] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = useCallback(async () => {
    try {
      const fetchedPolicies = await fetchConsumerPoliciesSummary()
      setPolicies(fetchedPolicies)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login?sessionExpired=true')
      }
      setIsLoading(false)
    }
  }, [navigate])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  if (isLoading) return <LoadingIndicator text="Loading Policies..." />
  return (
    <Fragment>
      {policies.map((policy) => (
        <PolicySummary key={`${policy.policyKey}-${policy.policyVersion}`} policy={policy} />
      ))}
    </Fragment>
  )
}

export default PolicySummaryWrapper
