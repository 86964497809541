import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingIndicator from '../LoadingIndicator'
import {
  fetchConsumerPolicyLetters,
  fetchConsumerPolicyReceivableNotices,
  fetchConsumerPolicyDocuments,
} from '../../utilities/apiService'
import UserDocumentTable from '../tables/UserDocumentTable'

const PolicyDocuments = () => {
  const params = useParams()
  const { policyKey, policyVersion } = params
  const navigate = useNavigate()
  const [letters, setLetters] = useState([])
  const [notices, setNotices] = useState([])
  const [documents, setDocuments] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = useCallback(async () => {
    try {
      // const fetchedLetters = await fetchConsumerPolicyLetters(policyKey, policyVersion)
      // const fetchedNotices = await fetchConsumerPolicyReceivableNotices(policyKey, policyVersion)
      const fetchedDocuments = await fetchConsumerPolicyDocuments(policyKey, policyVersion)
      // setLetters(await fetchedLetters)
      // setNotices(await fetchedNotices)
      setDocuments(await fetchedDocuments)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login?sessionExpired=true')
      }
      setIsLoading(false)
    }
  }, [navigate, policyKey, policyVersion])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  if (isLoading) return <LoadingIndicator text="Loading Documents & Letters..." />
  return (
    <Fragment>
      <UserDocumentTable documents={documents} title="Policy Documents" type="documents" />
      {/* <UserDocumentTable documents={notices} title="Policy Receivable Notices" type="notices" />
      <UserDocumentTable documents={letters} title="Policy Letters" type="letters" /> */}
    </Fragment>
  )
}

export default PolicyDocuments
