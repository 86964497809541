import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoadingIndicator from '../LoadingIndicator'
import { fetchConsumerLetters, fetchConsumerReceivableNotices } from '../../utilities/apiService'
import UserDocumentTable from '../tables/UserDocumentTable'

const LettersAndDocuments = () => {
  const navigate = useNavigate()
  const [letters, setLetters] = useState([])
  const [notices, setNotices] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = useCallback(async () => {
    try {
      const fetchedLetters = await fetchConsumerLetters()
      const fetchedNotices = await fetchConsumerReceivableNotices()
      setLetters(await fetchedLetters)
      setNotices(await fetchedNotices)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login?sessionExpired=true')
      }
      setIsLoading(false)
    }
  }, [navigate])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  if (isLoading) return <LoadingIndicator text="Loading Documents & Letters..." />
  return (
    <Fragment>
      <UserDocumentTable documents={notices} title="Receivable Notices" type="notices" />
      <UserDocumentTable documents={letters} title="Letters" type="letters" />
    </Fragment>
  )
}

export default LettersAndDocuments
