import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Container } from '@mui/material'
import LoadingIndicator from '../components/LoadingIndicator'

const Verify = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      const state = searchParams.get('state')
      const duo_code = searchParams.get('duo_code')
      const request = await axios.post('/auth/verify', { state, duo_code })
      localStorage.setItem('token', request.data.token)
      const { url, message } = request.data
      navigate(url, { state: { message } })
    }

    fetchData().catch((error) => {
      console.error(error)
    })
  }, [searchParams, navigate])
  return (
    <Container
      sx={{
        height: '100vh',
        width: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <LoadingIndicator text="Logging In..." />
    </Container>
  )
}

export default Verify
