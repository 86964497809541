import React, { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import DownloadIcon from '@mui/icons-material/Download'
import { downloadDocument } from '../../utilities/apiService'

const DownloadButton = ({ documentKey, fileName }) => {
  const [isLoading, setIsLoading] = useState(false)
  const downloadDoc = async () => {
    // Get base64 Document and download as PDF
    try {
      setIsLoading(true)
      const documentData = await downloadDocument(documentKey)
      const linkSource = `data:application/pdf;base64,${documentData}`
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      throw new Error(error)
    }
  }

  return (
    <LoadingButton
      size="small"
      color="primary"
      onClick={downloadDoc}
      loading={isLoading}
      loadingPosition="start"
      startIcon={<DownloadIcon />}
      variant="outlined">
      Download PDF
    </LoadingButton>
  )
}

export default DownloadButton
