import React from 'react'
import BrokerDownloadButton from '../broker/BrokerDownloadButton'
import { Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material'
import { Box, Typography } from '@mui/material'

const tableStyle = {
  color: 'primary.main',
  border: `10px solid rgba(37, 83, 121, 0.2)`,
  borderCollapse: 'separate',
  borderRadius: '4px',
  boxShadow: `rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px`,
  '& .MuiTableRow-root': {
    '&:nth-of-type(2n)': {
      backgroundColor: '#f8f6ff',
    },
  },
}

const DocumentTable = ({ documents, title, type }) => {
  return (
    <Box sx={{ mt: 4, mb: 7 }}>
      <Table sx={tableStyle}>
        <TableHead sx={{ backgroundColor: 'primary.main' }}>
          <TableRow>
            <TableCell colSpan="3">
              <Typography
                variant="h6"
                gutterBottom={false}
                noWrap
                component="h2"
                sx={{
                  fontWeight: 500,
                  color: 'white',
                }}>
                {title}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {documents?.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" colSpan="3" style={{ textAlign: 'center' }}>
                <Typography paragraph>No {type} available at this time</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <strong>Document</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Document Type</strong>
              </TableCell>
              <TableCell style={{ width: 180 }} />
            </TableRow>
            {documents.map((document) => (
              <TableRow key={document.documentKey}>
                <TableCell component="th" scope="row">
                  {document.description ? document.description : document.fileName}
                </TableCell>
                <TableCell align="right">{document.categoryName}</TableCell>
                <TableCell align="right" style={{ width: 180 }}>
                  <BrokerDownloadButton fileName={document.fileName} documentKey={document.documentKey} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </Box>
  )
}

export default DocumentTable
