import React from 'react'
import { useLocation } from 'react-router-dom'
import BrokerLayout from '../layouts/BrokerLayout'
import PolicyDetailWrapper from '../components/broker/PolicyDetailWrapper'
import { Typography, Breadcrumbs, Link, Box } from '@mui/material'

const BrokerPolicyDetail = ({ auth }) => {
  const location = useLocation()
  const policy = location.state
  return (
    <BrokerLayout auth={auth} pageTitle="Policy Details">
      <Box component="div" sx={{ py: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/broker">
            Lawyers' Mutual Policy Hub
          </Link>
          <Link underline="hover" color="inherit" href="/broker">
            Brokers
          </Link>
          <Link underline="hover" color="inherit" href="/broker/policies">
            Policies
          </Link>
          <Typography color="primary">
            <strong>Policy Details</strong>
          </Typography>
        </Breadcrumbs>
      </Box>
      <PolicyDetailWrapper policy={policy} />
    </BrokerLayout>
  )
}

export default BrokerPolicyDetail
