import React from 'react'
import { Helmet } from 'react-helmet-async'
import UserLayout from '../layouts/UserLayout'
import PolicySummaryWrapper from '../components/users/PolicySummaryWrapper'
import UserNav from '../components/users/UserNav'

const UserPolicies = ({ auth }) => {
  return (
    <UserLayout auth={auth}>
      <Helmet>
        <title>Policies Overview - Lawyers' Mutual Insurance Company Policy Hub</title>
      </Helmet>
      <UserNav />
      <PolicySummaryWrapper />
    </UserLayout>
  )
}

export default UserPolicies
