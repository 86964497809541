import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import RequireAuth from './utilities/RequireAuth'
import Home from './pages/Home'
import Signin from './pages/Signin'
import Register from './pages/Register'
import BrokerSignin from './pages/BrokerSignin'
import Verify from './pages/Verify'
import NotFound from './pages/NotFound'
import BrokerPolicyDetail from './pages/BrokerPolicyDetail'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import BrokerPolicies from './pages/BrokerPolicies'
import BrokerQuotes from './pages/BrokerQuotes'
import BrokerQuoteDetail from './pages/BrokerQuoteDetail'
import Profile from './pages/Profile'
import Admin from './pages/Admin'
import CreateUser from './pages/CreateUser'
import EditUser from './pages/EditUser'
import UserPolicies from './pages/UserPolicies'
import UserQuotes from './pages/UserQuotes'
import UserLetters from './pages/UserLetters'
import UserPolicyDetail from './pages/UserPolicyDetail'
import UserPolicyDocuments from './pages/UserPolicyDocuments'

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route
            path="/admin"
            element={
              <RequireAuth redirectTo="/login">
                <Admin />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/create-user"
            element={
              <RequireAuth redirectTo="/login">
                <CreateUser />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/edit-user"
            element={
              <RequireAuth redirectTo="/login">
                <EditUser />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Signin />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/broker"
            element={
              <RequireAuth redirectTo="/broker/login">
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/broker/policies"
            element={
              <RequireAuth redirectTo="/broker/login">
                <BrokerPolicies />
              </RequireAuth>
            }
          />
          <Route
            path="/broker/policies/policy-details"
            element={
              <RequireAuth redirectTo="/broker/login">
                <BrokerPolicyDetail />
              </RequireAuth>
            }
          />
          <Route
            path="/broker/quotes"
            element={
              <RequireAuth redirectTo="/broker/login">
                <BrokerQuotes />
              </RequireAuth>
            }
          />
          <Route
            path="/broker/quotes/quote-details"
            element={
              <RequireAuth redirectTo="/broker/login">
                <BrokerQuoteDetail />
              </RequireAuth>
            }
          />
          <Route
            path="/broker/quotes/quote-details"
            element={
              <RequireAuth redirectTo="/broker/login">
                <BrokerQuoteDetail />
              </RequireAuth>
            }
          />
          <Route path="/broker/login" element={<BrokerSignin />} />
          <Route
            path="/profile"
            element={
              <RequireAuth redirectTo="/broker/login">
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/policies"
            element={
              <RequireAuth redirectTo="/login">
                <UserPolicies />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/policies/:policyKey"
            element={
              <RequireAuth redirectTo="/login">
                <UserPolicyDetail />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/policies/:policyKey/:policyVersion/documents"
            element={
              <RequireAuth redirectTo="/login">
                <UserPolicyDocuments />
              </RequireAuth>
            }
          />
          <Route
            path="/policies/quotes"
            element={
              <RequireAuth redirectTo="/login">
                <UserQuotes />
              </RequireAuth>
            }
          />
          <Route
            path="/policies/documents-letters"
            element={
              <RequireAuth redirectTo="/login">
                <UserLetters />
              </RequireAuth>
            }
          />
          <Route
            path="/"
            element={
              <RequireAuth redirectTo="/login">
                <Home />
              </RequireAuth>
            }
          />
        </Routes>
      </Router>
    </HelmetProvider>
  )
}

export default App
