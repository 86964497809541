import React, { Fragment } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { LoadingButton } from '@mui/lab'
import { Container, TextField, Typography, Stack, CircularProgress } from '@mui/material'

const EditUserForm = ({ onSubmit, message, isLoading = false }) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Must be a valid email').required('Email is required'),
    clientKey: Yup.string().required('Client Key is required'),
    zipCode: Yup.string().required('Zipcode is required'),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      clientKey: '',
      zipCode: '',
    },
  }
  const { handleSubmit, formState, control } = useForm(formOptions)
  const { errors } = formState

  const submitForm = (data) => {
    onSubmit(data)
  }

  return (
    <Fragment>
      {message && (
        <Typography paragraph sx={{ color: `${message.status}.main` }}>
          {message.message}
        </Typography>
      )}
      <Container
        component="form"
        autoComplete="off"
        maxWidth="md"
        disableGutters
        sx={{
          mx: 0,
        }}
        onSubmit={handleSubmit(submitForm)}>
        <Stack spacing={2} direction="row">
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.firstName}
                label="First Name"
                helperText={errors.firstName?.message}
                variant="standard"
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.lastName}
                label="Last Name"
                helperText={errors.lastName?.message}
                variant="standard"
              />
            )}
          />
        </Stack>
        <Stack mt={2} spacing={2} direction="row">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.email}
                label="Email"
                helperText={errors.email?.message}
                variant="standard"
              />
            )}
          />
        </Stack>
        <Stack mt={2} spacing={2} direction="row">
          <Controller
            name="clientKey"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.clientKey}
                label="Client Key"
                helperText={errors.clientKey?.message}
                variant="standard"
              />
            )}
          />
          <Controller
            name="zipCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.zipCode}
                label="Zip Code"
                helperText={errors.zipCode?.message}
                variant="standard"
              />
            )}
          />
        </Stack>

        <Stack mt={4} spacing={2} direction="row">
          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            disabled={isLoading}
            disableElevation
            color="secondary"
            size="large"
            loadingIndicator={<CircularProgress color="inherit" size={16} thickness={5} />}
            sx={{
              minWidth: '150px',
            }}>
            Register My Account
          </LoadingButton>
        </Stack>
      </Container>
    </Fragment>
  )
}

export default EditUserForm
