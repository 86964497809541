import React, { useState } from 'react'
import { Box } from '@mui/material'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import PolicyDetail from './PolicyDetail'
import QuoteDetail from './QuoteDetail'

const PolicyDetailWrapper = ({ policy }) => {
  const { futurePolicy, quote } = policy
  const [value, setValue] = useState('1')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Current Inforce Policy" value="1" />
            {futurePolicy && <Tab label="Future Effective Policy" value="2" />}
            {quote && <Tab label="Quote" value="3" />}
          </TabList>
        </Box>
        <TabPanel value="1">
          <PolicyDetail policy={policy} />
        </TabPanel>
        {futurePolicy && (
          <TabPanel value="2">
            <PolicyDetail policy={futurePolicy} />
          </TabPanel>
        )}
        {quote && (
          <TabPanel value="3">
            <QuoteDetail quote={quote} />
          </TabPanel>
        )}
      </TabContext>
    </Box>
  )
}

export default PolicyDetailWrapper
