import React from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { formatMoney } from '../../utilities/formatMoney'
import SearchIcon from '@mui/icons-material/Search'
import DescriptionIcon from '@mui/icons-material/Description'
import MailIcon from '@mui/icons-material/Mail'

const PolicyOverview = styled(Box)`
  border-bottom: 1px solid #979797;
  padding: 25px;
  &:last-of-type {
    border-bottom: none;
  }
`
const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding-bottom: 10px;
`

const PaymentInfo = styled('div')`
  text-align: right;
`

const LinkButton = styled(Button)`
  border-radius: 0;
  min-width: 175px;
  margin-right: 20px;
`

const PolicySummary = ({ policy }) => {
  const { policyKey, policyVersion, classOfBusiness, policyEffectiveDate, policyExpiryDate, balance, clientName } =
    policy
  return (
    <PolicyOverview>
      <Row>
        <div>
          <Typography
            variant="h6"
            component="h2"
            color="text.primary"
            sx={{
              fontWeight: 400,
            }}>
            {clientName} - {policyKey} - v.{policyVersion} - {classOfBusiness}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Effective Date: {dayjs(policyEffectiveDate).format('MM/DD/YYYY')} - Expiry Date:{' '}
            {dayjs(policyExpiryDate).format('MM/DD/YYYY')}
          </Typography>
        </div>
        <PaymentInfo>
          <Typography variant="body2" color="text.secondary">
            Current Balance
          </Typography>
          <Typography variant="h6" gutterBottom component="h3" color="text.secondary">
            {formatMoney(balance)}
          </Typography>
        </PaymentInfo>
      </Row>
      <Row>
        <div>
          <LinkButton
            component={Link}
            to={`/policies/${policyKey}`}
            variant="outlined"
            disableElevation
            startIcon={<SearchIcon />}>
            Summary
          </LinkButton>
          <LinkButton
            component={Link}
            to={`/policies/${policyKey}/${policyVersion}/documents`}
            variant="outlined"
            disableElevation
            startIcon={<DescriptionIcon />}>
            Documents
          </LinkButton>
          {/* <LinkButton component={Link} to="/" variant="outlined" disableElevation startIcon={<MailIcon />}>
            Request Change
          </LinkButton> */}
        </div>
        <Button
          variant="contained"
          disableElevation
          size="large"
          disabled={balance <= 0}
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.lawyersmutual.com/paynow">
          {balance <= 0 ? 'No Payment Due' : 'Make A Payment'}
        </Button>
      </Row>
    </PolicyOverview>
  )
}

export default PolicySummary
