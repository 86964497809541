import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import LoadingIndicator from '../LoadingIndicator'
import DocumentTable from '../tables/DocumentTable'
import { Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material'
import { Box, Typography } from '@mui/material'
import { fetchBrokerQuoteDetails } from '../../utilities/apiService'

const tableStyle = {
  color: 'primary.main',
  border: `10px solid rgba(37, 83, 121, 0.2)`,
  borderCollapse: 'separate',
  borderRadius: '4px',
  boxShadow: `rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px`,
  '& .MuiTableRow-root': {
    '&:nth-of-type(2n)': {
      backgroundColor: '#f8f6ff',
    },
  },
}

const QuoteDetail = ({ quote }) => {
  const navigate = useNavigate()
  const { quoteKey, quoteVersion } = quote
  const [documents, setDocuments] = useState([])
  const [quoteDetails, setQuoteDetails] = useState([]) // eslint-disable-line
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = useCallback(async () => {
    try {
      const { documents, quoteDetails } = await fetchBrokerQuoteDetails(quoteKey, quoteVersion)
      setDocuments(documents)
      setQuoteDetails(quoteDetails)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/broker/login?sessionExpired=true')
      }
      setIsLoading(false)
    }
  }, [navigate, quoteKey, quoteVersion])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <>
      {isLoading ? (
        <Fragment>
          <LoadingIndicator text="Loading Quote Details..." />
        </Fragment>
      ) : (
        <Fragment>
          <Box sx={{ mt: 2, mb: 7 }}>
            <Table sx={tableStyle}>
              <TableHead sx={{ backgroundColor: 'primary.main' }}>
                <TableRow>
                  <TableCell colSpan="2">
                    <Typography
                      variant="h6"
                      gutterBottom={false}
                      noWrap
                      component="h2"
                      sx={{
                        fontWeight: 500,
                        color: 'white',
                      }}>
                      {quote.quoteKey} - {quote.clientName}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Client Name</strong>
                  </TableCell>
                  <TableCell align="right">{quote.clientName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Quote Number</strong>
                  </TableCell>
                  <TableCell align="right">{quote.quoteKey}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Policy Effective Date</strong>
                  </TableCell>
                  <TableCell align="right">{dayjs(quote.policyEffectiveDate).format('MM/DD/YYYY')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Policy Expiry Date</strong>
                  </TableCell>
                  <TableCell align="right">{dayjs(quote.policyExpiryDate).format('MM/DD/YYYY')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Quote Version</strong>
                  </TableCell>
                  <TableCell align="right">{quote.quoteVersion}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Broker Name</strong>
                  </TableCell>
                  <TableCell align="right">{quote.brokerName}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <DocumentTable title="Quote Documents" documents={documents} type="documents" />
        </Fragment>
      )}
    </>
  )
}

export default QuoteDetail
