import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import NavLink from './NavLink'
import axios from 'axios'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Button } from '@mui/material'

const NavLinks = `
display: inline-block;
background: transparent;
border: 0;
border-radius:0;
color: white;
font-weight: bold;
font-size: 14px;
text-decoration: none;
line-height: 1.5;
display: inline-block;
text-transform: uppercase;
padding: 10px 25px 15px;
vertical-align: bottom;
&:hover {
  background: rgba(255,255,255, .1)
};
&.active{
  background: white;
  color: #1A1449;
};
`

const NavBarLink = styled(NavLink)`
  ${NavLinks}
`
const NavBarAnchorLink = styled('a')`
  ${NavLinks}
`

const NavBarButton = styled(Button)`
  ${NavLinks}
`

const Navbar = ({ auth }) => {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const { role } = auth?.user
  const url = role === 'broker' ? '/broker/login' : '/login'
  const handleLogout = async () => {
    await axios
      .get('/auth/logout', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        // Clear localStorage and state
        localStorage.removeItem('token')
        navigate(url)
      })
      .catch((error) => {
        console.log('Error logging out')
      })
  }
  return (
    <Box
      component="div"
      sx={{
        py: 0,
        px: 2,
        mt: 'auto',
        backgroundColor: 'primary.dark',
      }}>
      <Container
        component="nav"
        maxWidth="lg"
        sx={{
          padding: 0,
          height: '60px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}>
        <div>
          {auth.user.role === 'admin' && (
            <Fragment>
              <NavBarLink activeClassName="active" to="/admin">
                Manage Users
              </NavBarLink>
            </Fragment>
          )}
          {(auth.user.role === 'brokerAdmin' || auth.user.role === 'broker') && (
            <Fragment>
              <NavBarLink activeClassName="active" to="/broker/policies">
                Policies
              </NavBarLink>
              <NavBarLink activeClassName="active" to="/broker/quotes">
                Quotes
              </NavBarLink>
            </Fragment>
          )}
          {auth.user.role === 'user' && (
            <Fragment>
              <NavBarLink activeClassName="active" to="/">
                Home
              </NavBarLink>
              <NavBarLink activeClassName="active" to="/policies">
                Policies
              </NavBarLink>
              <NavBarAnchorLink rel="noopener noreferrer" target="_blank" href="https://www.lawyersmutual.com/paynow">
                Billing
              </NavBarAnchorLink>
            </Fragment>
          )}
          <NavBarAnchorLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.lawyersmutual.com/portal-resources">
            Resources
          </NavBarAnchorLink>
          <NavBarAnchorLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.lawyersmutual.com/portal-contact">
            Contact
          </NavBarAnchorLink>
        </div>
        <div>
          <NavBarLink activeClassName="active" to="/profile">
            {auth.user.firstName} {auth.user.lastName}
          </NavBarLink>
          <NavBarButton onClick={handleLogout}>Log Out</NavBarButton>
        </div>
      </Container>
    </Box>
  )
}

export default Navbar
