import React from 'react'
import { Helmet } from 'react-helmet-async'
import UserLayout from '../layouts/UserLayout'
import UserNav from '../components/users/UserNav'
import { Box } from '@mui/material'
import LettersAndDocuments from '../components/users/LettersAndDocuments'
// import QuoteSummary from '../components/users/QuoteSummary'

const UserLetters = ({ auth }) => {
  return (
    <UserLayout auth={auth}>
      <Helmet>
        <title>Letters & Documents - Lawyers' Mutual Insurance Company Policy Hub</title>
      </Helmet>
      <UserNav />
      <Box sx={{ padding: '10px 25px' }}>
        <LettersAndDocuments />
      </Box>
    </UserLayout>
  )
}

export default UserLetters
