import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography, Breadcrumbs, Link, Box, Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import SecureLayout from '../layouts/SecureLayout'
import UpdatePasswordForm from '../components/forms/UpdatePasswordForm'
import ProfileInfo from '../components/forms/ProfileInfo'
import { updatePassword } from '../utilities/apiService'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />
})

const Profile = ({ auth }) => {
  const [state, setState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    message: '',
    severity: 'success',
  })

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const onPasswordSubmit = async (data) => {
    try {
      const request = await updatePassword(data)
      if (request.token) {
        localStorage.setItem('token', request.token)
        setState({ ...state, open: true, message: request.message, severity: 'success' })
      }
    } catch (error) {
      setState({ ...state, open: true, message: 'error.response.data.message', severity: 'error' })
    }
  }

  const { vertical, horizontal, open, message, severity } = state
  return (
    <SecureLayout auth={auth} pageTitle="Profile">
      <Helmet>
        <title>Edit Profile - Lawyers' Mutual Insurance Company Policy Hub</title>
      </Helmet>
      <Box component="div" sx={{ py: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Lawyers' Mutual Policy Hub
          </Link>
          <Typography color="text.primary">Edit Your Profile</Typography>
        </Breadcrumbs>
      </Box>
      <div className="padded-wrapper">
        <Typography
          variant="h5"
          gutterBottom
          component="h2"
          sx={{
            fontWeight: 400,
          }}>
          Profile Information
        </Typography>
        <ProfileInfo user={auth.user} />
      </div>
      <div className="padded-wrapper">
        <Typography
          variant="h5"
          gutterBottom
          component="h2"
          sx={{
            fontWeight: 400,
          }}>
          Update Password
        </Typography>
        <UpdatePasswordForm onSubmit={onPasswordSubmit} buttonValue="Update Password" />
      </div>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </SecureLayout>
  )
}

export default Profile
