import React, { useCallback, useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { fetchUsers, fetchBrokers, deleteUser } from '../../utilities/apiService'
import { find } from 'lodash'
import { DataGrid } from '@mui/x-data-grid'
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
const tableStyle = {
  mt: 2,
  color: 'primary.main',
  border: `10px solid rgba(37, 83, 121, 0.2)`,
  boxShadow: `rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px`,
  '& .MuiDataGrid-row': {
    '&:nth-of-type(2n)': {
      backgroundColor: '#f8f6ff',
    },
    '&:hover': {
      backgroundColor: 'rgba(37, 83, 121, 0.2)',
    },
  },
  '& .MuiDataGrid-cell': {},
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
}))

const UsersTable = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [brokers, setBrokers] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = useCallback(async () => {
    try {
      const brokers = await fetchBrokers()
      setBrokers(brokers)

      const users = await fetchUsers()
      setUsers(users)

      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login?sessionExpired=true')
      }
      setIsLoading(false)
    }
  }, [navigate])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleClose = () => {
    setOpen(false)
  }

  const renderBrokerName = (brokerCode) => {
    const broker = find(brokers, { brokerCode: brokerCode })
    return broker.brokerName
  }

  const handleEditUser = (user) => {
    navigate('/admin/edit-user', { state: user })
  }

  const confirmDeleteUser = async () => {
    try {
      await deleteUser(selectedUser.id)
      const users = await fetchUsers()
      setUsers(users)
      setSelectedUser({})
      setOpen(false)
    } catch (error) {
      setOpen(false)
    }
  }

  const handleDeleteUser = (user) => {
    setSelectedUser(user)
    setOpen(true)
  }

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'lastName',
      disableColumnMenu: true,
      headerName: 'Last Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: 'role',
      headerName: 'User Role',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        switch (params.row.role) {
          case 'user':
            return 'Direct Insured'
          default:
            return capitalize(params.value)
        }
      },
    },
    {
      field: 'key',
      headerName: 'Broker / Client Key',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => {
        switch (params.row.role) {
          case 'user':
            return params.value
          case 'broker':
            return renderBrokerName(params.value)
          default:
            return <span style={{ color: 'grey' }}>N/A</span>
        }
      },
    },
    {
      field: 'edit',
      type: 'actions',
      align: 'center',
      disableColumnMenu: true,
      width: 60,
      renderCell: (params) => (
        <Tooltip title="Edit User">
          <IconButton
            color="primary"
            variant="contained"
            size="small"
            onClick={() => handleEditUser(params.row)}
            aria-label="Edit User">
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'delete',
      type: 'actions',
      align: 'center',
      disableColumnMenu: true,
      width: 60,
      renderCell: (params) => (
        <Tooltip title="Delete User">
          <IconButton
            color="primary"
            variant="contained"
            size="small"
            onClick={() => handleDeleteUser(params.row)}
            aria-label="Delete User">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ]

  const TableToolBar = () => {
    return (
      <AppBar position="relative">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            gutterBottom={false}
            noWrap
            component="div"
            sx={{
              fontWeight: 500,
            }}>
            Policy Hub Users
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <ColorButton variant="contained" size="large" href="/admin/create-user" endIcon={<PersonAddAlt1Icon />}>
              Create User
            </ColorButton>
          </Box>
        </Toolbar>
      </AppBar>
    )
  }

  return (
    <Fragment>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastName', sort: 'asc' }],
          },
        }}
        autoHeight
        disableSelectionOnClick={true}
        components={{ Toolbar: TableToolBar }}
        rows={users}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        pagination
        columns={columns}
        pageSize={pageSize}
        loading={isLoading}
        sx={tableStyle}
      />
      {selectedUser && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{`Delete User: ${selectedUser.firstName} ${selectedUser.lastName} (${selectedUser.email})?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Once this user is deleted they will no longer have access to their account. This action is not reversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={confirmDeleteUser} autoFocus>
              Delete User
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  )
}

export default UsersTable
