import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Link, Typography, Breadcrumbs } from '@mui/material'
import AdminLayout from '../layouts/AdminLayout'
import UsersTable from '../components/admin/UsersTable'

const Admin = ({ auth }) => {
  return (
    <AdminLayout auth={auth}>
      <Helmet>
        <title>Admin - Lawyers' Mutual Insurance Company Policy Hub</title>
      </Helmet>
      <Box component="div" sx={{ py: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Lawyers' Mutual Policy Hub
          </Link>
          <Typography color="primary">
            <strong>Admin</strong>
          </Typography>
        </Breadcrumbs>
      </Box>
      <UsersTable />
    </AdminLayout>
  )
}

export default Admin
