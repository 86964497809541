import React, { Fragment } from 'react'
import { useSearchParams } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import TitleBanner from '../components/TitleBanner'
import { Container, Box, Alert } from '@mui/material'
function DefaultLayout({ children, pageTitle }) {
  const [searchParams] = useSearchParams()
  const sessionExpired = searchParams.get('sessionExpired')
  if (sessionExpired) {
    localStorage.removeItem('token')
  }
  return (
    <Fragment>
      {sessionExpired && (
        <Alert severity="info" sx={{ textAlign: 'center', justifyContent: 'center' }}>
          Your session has expired, please log in
        </Alert>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <TitleBanner pageTitle={pageTitle} />
        <Container maxWidth="lg">
          <Box component="main" sx={{ py: 5, mt: 'auto' }}>
            {children}
          </Box>
        </Container>
        <Footer />
      </Box>
    </Fragment>
  )
}

export default DefaultLayout
