import React, { useState } from 'react'
import SecureLayout from '../layouts/SecureLayout'
import UpdatePasswordForm from '../components/forms/UpdatePasswordForm'
import { Typography } from '@mui/material'
import { updatePassword } from '../utilities/apiService'

const UpdatePassword = ({ auth }) => {
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      const request = await updatePassword(data)
      if (request.token) {
        localStorage.setItem('token', request.token)
        window.location.href = request.url
      }
    } catch (error) {
      setIsLoading(false)
      setMessage({ status: 'error', message: error.response.data.message })
    }
  }
  return (
    <SecureLayout auth={auth}>
      <Typography
        variant="h5"
        gutterBottom
        component="h2"
        sx={{
          fontWeight: 400,
        }}>
        Update Your Password
      </Typography>
      <Typography paragraph>
        {auth.user.firstName && (
          <span>
            Hello <strong>{auth.user.firstName}</strong>!{' '}
          </span>
        )}
        Since this is your first time logging in to the Lawyers' Mutual Policy Hub, please set a new password.
      </Typography>
      {message && (
        <Typography paragraph sx={{ color: `${message.status}.main` }}>
          {message.message}
        </Typography>
      )}
      <UpdatePasswordForm onSubmit={onSubmit} isLoading={isLoading} />
    </SecureLayout>
  )
}

export default UpdatePassword
